.map-container{
    width: 100%;
    height: 100%;
    position: relative;
}
.map-info{
    position: absolute;
    right: 0;
    top: 95%;
    background: white;
}
.map-info-text{
    margin: 0px 10px 5px 10px;
}
.interactive-map{
    width: 100%;
    height: 100%;
}
.local-map{
    width: 100%;
    height: 100%;
}
/* .interactive-map .ol-unselectable .ol-layer:first-child {
    filter: grayscale(100%);
} */
.control-map-item-overlay{
    background: rgba(255,255,255,0.7);
    padding: 5px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: max-content;
    border: 0.5px solid black;
    pointer-events: none;
    z-index: 10;
    user-select: none;
}

.mode-selector-container{
    position: absolute;
    right: 0;
    top:20%
}
