body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif; */
  font-family: "Heebo";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-gutter: stable both-edges;

}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;   */
  font-family: "Heebo";
}

html, body{
  height: 100% !important;
  width: 100% !important;
  margin-left: calc(100vw - 100%); 
  box-sizing: border-box; 
}

#root{
  height: 100%;
}

@media print {
  .no-print {
    display: none; /* Hide elements that should not be printed */
  }
  html{
    padding: 0px !important;
    background-color: white !important;
    text-decoration: none !important;
    border-color: black !important;
  }
  td,th, tr, p, .MuiBox-root, .MuiContainer-root, .MuiTableContainer-root * {
    color: black !important; /* Example: Set the typography color to black for printing */
    background-color: white !important;
    text-decoration: none !important;
    border-color: black !important;
    padding: 1 !important;
    font-size: 1em !important;
    max-height: max-content !important;
    height: fit-content !important;
  }
}