.HierarchyHistory{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* gap: 20px; */
}

.HistoryDivider {
    flex-grow: 1;
    border-bottom: 1px solid #3b85b8;
    padding-inline: 20px;
    margin: 0 10px;
}
