.map-container{
    width: 100%;
    height: 100%;
    position: relative;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
}
.map-info{
    position: absolute;
    right: 0;
    top: 0;
    background: white;
}

.button-container{
    position: absolute;
    flex-direction: column;
    right: 1%;
    top: 25%;
    width: 50px;
    height: 120px;
    display: flex;
    justify-content: center; 
    align-items: center;
}

/* button{
    padding: 32px;
    font-size: 64px;
    border: 1px solid #ccc;
    background-color: #f0f0f0;
    color: #333;
    cursor: pointer;
} */

.button-krizek{
    width: 16px;
    height: 16px;
    font-size: 16px;
    border: 1px solid #ccc;
    background-color: #f0f0f0;
    color: #333;
    cursor: pointer;
    align-items: center;
}

/* button.pressed{
    background-color: #868585;
} */


.map-info-text{
    margin: 0px 10px 5px 10px;
}
.interactive-map{
    width: 100%;
    height: 80%;
    position: relative;
}

.control-map-item-overlay{
    background: rgba(255,255,255,0.7);
    padding: 5px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: max-content;
    border: 0.5px solid black;
    pointer-events: none;
    z-index: 10;
    user-select: none;

}
