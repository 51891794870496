.gutter {
    background-color: white;
    background-repeat: no-repeat;
    background-position: 50%;
}

.gutter.gutter-horizontal {
    cursor: col-resize;
    width: 50px;
}

.gutter.gutter-horizontal img{
    cursor: col-resize;
    width: 50px;
    position: absolute;
    z-index: 10;
    top:50%;
    transform: translate(-15px, 0);
}